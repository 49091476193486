import React, { useState, useEffect, useRef } from 'react';
import { TextField, Checkbox, FormControlLabel, IconButton, InputAdornment, Divider } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { postLogin } from '../../services/repositories/LoginService';
import { postLoginGoogle } from '../../services/repositories/LoginGoogleService';
import CustomDialog from '../customdialog/CustomDialog';

function LoginDialog({ open, onClose, onLoginSuccess }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const googleButtonRef = useRef(null);

  const handleCredentialResponse = (async (response) => {
    if (response.credential) {
      setLoading(true);
      setError('');
      const decodedCredential = parseJwt(response.credential);

      try {
        const response = await postLoginGoogle({
          name: decodedCredential.name,
          email: decodedCredential.email
        });

        if (response && typeof response === 'object') {
          if (response.code !== 2000){
            setLoading(false); 
            setError(response.message);
            
          } else if (response.accessToken) {
            onLoginSuccess({
              email: decodedCredential.email,
              accessToken: response.accessToken,
            });
            onClose();
          } else {
            throw new Error('Access token missing in response');
          }
        } else {
          throw new Error('Invalid response format');
        }
      } catch (err) {
        setError(err.message);
        console.error('Error during Google login:', err.message || err);
      } finally {
        setLoading(false);
      }
    } else {
      setError('Google Sign-In Error');
    }
  });

  const initializeGoogleSignIn = (() => {
    if (window.google?.accounts?.id && googleButtonRef.current) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_CLIENT_ID?.trim(),
        callback: handleCredentialResponse,
      });
    }
    
  
    try {
      if (window.google.accounts.id && googleButtonRef.current) {
        window.google.accounts.id.renderButton(googleButtonRef.current, {
          theme: "outline",
          size: "large",
          text: "signin_with",
          shape: "rectangular",
        });
      }
    } catch (e) {
      console.error("Error initializing Google Sign-In:", e);
    }
  });

  useEffect(() => {
    if (googleButtonRef.current) {
      const script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.async = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }); 
    
      const parseJwt = (token) => {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
            .join('')
        );
        return JSON.parse(jsonPayload);
      };

  const handleLogin = async () => {
    setLoading(true);
    setError('');

    try {
      const response = await postLogin({
        email,
        password,
        rememberMe: rememberMe ? '1' : '0',
      });

      if (response && typeof response === 'object') {
        if (response.accessToken) {
          onLoginSuccess({
            email: email,
            accessToken: response.accessToken,
          });
          onClose();
        } else {
          throw new Error('Access token missing in response');
        }
      } else {
        throw new Error('Invalid response format');
      }
    } catch (err) {
      setError('Login failed. Please check your credentials.');
      console.error('Error during login:', err.message || err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      title="Login"
      onSubmit={handleLogin}
      submitLabel="Login"
      submitLoading={loading}
      submitDisabled={loading}
    >
      <div
        id="google-sign-in-button"
        ref={googleButtonRef}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pointerEvents: loading ? 'none' : 'auto',
          opacity: loading ? 0.5 : 1, 
        }}
      ></div>

      <Divider
        sx={{
          marginY: 2,
          '&::before, &::after': {
            borderColor: '#581a7d',
          },
        }}
      >
        OR
      </Divider>

      <TextField
        label="Username"
        type="text"
        fullWidth
        variant="outlined"
        margin="normal"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        InputProps={{
          sx: {
            fontFamily: 'PT Sans',
            color: '#52070c',
          },
        }}
        InputLabelProps={{
          sx: {
            fontFamily: 'PT Sans',
            color: '#52070c',
            '&.Mui-focused': {
              color: '#52070c',
            },
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#52070c',
            },
            '&:hover fieldset': {
              borderColor: '#52070c',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#52070c',
            },
          },
        }}
      />
      <TextField
        label="Password"
        type={showPassword ? 'text' : 'password'}
        fullWidth
        variant="outlined"
        margin="normal"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
          sx: {
            fontFamily: 'PT Sans',
            color: '#52070c',
          },
        }}
        InputLabelProps={{
          sx: {
            fontFamily: 'PT Sans',
            color: '#52070c',
            '&.Mui-focused': {
              color: '#52070c',
            },
          },
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#52070c',
            },
            '&:hover fieldset': {
              borderColor: '#52070c',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#52070c',
            },
          },
        }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
            sx={{
              color: '#52070c',
              '&.Mui-checked': {
                color: '#52070c',
              },
            }}
          />
        }
        label="Remember me"
        sx={{
          fontFamily: 'PT Sans',
          color: '#52070c',
        }}
      />
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </CustomDialog>
  );
}

export default LoginDialog;
