import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, IconButton, Box, useMediaQuery, useTheme, Drawer, List, ListItem, ListItemText, ListItemIcon, Divider } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu'; 
import { Link, useNavigate } from 'react-router-dom'; 
import LoginDialog from '../../components/logindialog/LoginDialog'; 
import RegisterDialog from '../../components/registerdialog/RegisterDialog';
import { postLogout } from '../../services/repositories/LogoutService';
import logo from '../../assets/images/logo.webp';
import logo2 from '../../assets/images/logo2.webp'; 

function CustomAppBar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const [isRegisterDialogOpen, setIsRegisterDialogOpen] = useState(false); 
  const [userEmail, setUserEmail] = useState(null);
  const navigate = useNavigate();

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    const email = localStorage.getItem('email');
    // console.log(token);
    // console.log(email);
    if (token) {
      setUserEmail(email);
    }
  }, []);

  const handleMenuClick = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLoginSuccess = (data) => {
    setUserEmail(data.email);
    localStorage.setItem('accessToken', data.accessToken); 
    localStorage.setItem('email', data.email); 
    setIsDrawerOpen(false);
    // navigate('/booking');
    // window.location.reload();
  };

  const handleRegisterSuccess = (data) => {
    if(data){
      setUserEmail(data.email);
      localStorage.setItem('accessToken', data.accessToken); 
      localStorage.setItem('email', data.email); 
    }
    setIsDrawerOpen(false);
  };

  const handleLogout = async () => {
    try {
      await postLogout(); 
      setUserEmail(null);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('email');
      setIsDrawerOpen(false);
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error.message || error);
      setUserEmail(null);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('email');
      setIsDrawerOpen(false);
      navigate('/');
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <AppBar
        position="fixed" 
        elevation={0} 
        sx={{
          backgroundColor: scrolled ? '#fff8f0' : 'rgba(137,117,44,0.5)',
          height: scrolled ? '80px' : '64px',
          transition: 'background-color 0.3s ease, height 0.3s ease',
          zIndex: 5,
          paddingTop: scrolled ? '8px' : '0px'
        }}
      >
        <Toolbar disableGutters>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              padding: { xs: '5px 16px', sm: '5px 24px', md: '5px 50px', lg: '5px 150px' },
            }}
          >
            <Link to="/" style={{ textDecoration: 'none' }}> 
              <img 
                src={scrolled ? logo2 : logo} 
                alt="Logo" 
                style={{ 
                  height: 'auto', 
                  marginRight: '16px',
                  width: 'auto',
                  paddingTop: '8px'
                }} 
              />
            </Link>
            
            <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
              <Button
                onClick={() => {
                  // if (userEmail) {
                  //   window.location.href = '/product-list'; 
                  // } else {
                  //   setIsLoginDialogOpen(true); 
                  // }
                  window.location.href = '/product-list'; 
                }}
                sx={{
                  color: scrolled ? '#52070C' : 'white',
                  fontFamily: 'PT Sans, sans-serif',
                  fontSize: '15px'
                }}
              >
                Products
              </Button>

              <Button
                onClick={() => {
                  if (userEmail) {
                    window.location.href = '/booking'; 
                  } else {
                    setIsLoginDialogOpen(true); 
                  }
                }}
                sx={{
                  color: scrolled ? '#52070C' : 'white',
                  fontFamily: 'PT Sans, sans-serif',
                  fontSize: '15px'
                }}
              >
                BOOKING
              </Button>
              <Button
                onClick={() => {
                  if (userEmail) {
                    window.location.href = '/promo';
                  } else {
                    setIsLoginDialogOpen(true);
                  }
                }}
                sx={{
                  color: scrolled ? '#52070C' : 'white',
                  fontFamily: 'PT Sans, sans-serif',
                  fontSize: '15px'
                }}
              >
                EVENTS
              </Button>

              <Button
                onClick={() => {
                  window.location.href = '/promotions';
                  // if (userEmail) {
                  //   window.location.href = '/promo';
                  // } else {
                  //   setIsLoginDialogOpen(true);
                  // }
                }}
                sx={{
                  color: scrolled ? '#52070C' : 'white',
                  fontFamily: 'PT Sans, sans-serif',
                  fontSize: '15px'
                }}
              >
                PROMOTIONS
              </Button>

              <Button
                onClick={() => {
                  if (userEmail) {
                    window.location.href = '/customer-service';
                  } else {
                    setIsLoginDialogOpen(true);
                  }
                }}
                sx={{
                  color: scrolled ? '#52070C' : 'white',
                  fontFamily: 'PT Sans, sans-serif',
                  fontSize: '15px'
                }}
              >
                CONTACT US
              </Button>

              {/* <Button
                onClick={() => {
                  if (userEmail) {
                    window.location.href = '/about-us';
                  } else {
                    setIsLoginDialogOpen(true);
                  }
                }}
                sx={{
                  color: scrolled ? '#52070C' : 'white',
                  fontFamily: 'PT Sans, sans-serif',
                  fontSize: '15px'
                }}
              >
                HELP CENTER
              </Button> */}
            </Box>

            <IconButton 
              color="inherit" 
              onClick={handleMenuClick}
              sx={{
                color: scrolled ? '#52070C' : 'white',
              }}
            >
              <MenuIcon />
            </IconButton>

            <Drawer
              anchor="right"
              open={isDrawerOpen}
              onClose={handleMenuClick}
              sx={{
                '& .MuiPaper-root': {
                  backgroundColor: 'rgba(255, 248, 255, 0.9)'
                },
              }}
            >
              <List>
                <ListItem sx={{ justifyContent: 'center', py: 2 }} component={Link} to="/" onClick={handleMenuClick}>
                  <img 
                    src={logo2} 
                    alt="Logo" 
                    style={{ 
                      height: 'auto', 
                      marginRight: '16px',
                      width: 'auto',
                    }} 
                  />
                </ListItem>

                <Divider sx={{ my: 1, borderColor: 'rgba(137, 117, 44, 0.3)' }} />

                {!userEmail && (
                  <>
                    <ListItem onClick={() => { setIsLoginDialogOpen(true); handleMenuClick(); }}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="login">🔒</span>
                      </ListItemIcon>
                      <ListItemText primary="Login" sx={{ fontFamily: 'PT Sans, sans-serif' }} />
                    </ListItem>
                    <ListItem onClick={() => { setIsRegisterDialogOpen(true); handleMenuClick(); }}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="register">📝</span>
                      </ListItemIcon>
                      <ListItemText primary="Register" sx={{ fontFamily: 'PT Sans, sans-serif' }} />
                    </ListItem>
                  </>
                )}

                {!isLargeScreen && (
                  <>
                    <ListItem component={Link} to="/" onClick={handleMenuClick}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="home">🏠</span>
                      </ListItemIcon>
                      <ListItemText primary="Home" sx={{ fontFamily: 'PT Sans, sans-serif', color: '#52070c' }} />
                    </ListItem>
                    <ListItem component={Link} to="/product-list" onClick={handleMenuClick}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="products">📦</span>
                      </ListItemIcon>
                      <ListItemText primary="Products" sx={{ fontFamily: 'PT Sans, sans-serif', color: '#52070c' }} />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to={userEmail ? "/booking" : "#"} 
                      onClick={() => {
                        if (!userEmail) {
                          setIsLoginDialogOpen(true);  
                        }
                        handleMenuClick(); 
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="booking">📅</span>
                      </ListItemIcon>
                      <ListItemText primary="Booking" sx={{ fontFamily: 'PT Sans, sans-serif', color: '#52070c' }} />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to={userEmail ? "/promo" : "#"}
                      onClick={() => {
                        if (!userEmail) {
                          setIsLoginDialogOpen(true);
                        }
                        handleMenuClick();
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="events">🎉</span>
                      </ListItemIcon>
                      <ListItemText primary="Events" sx={{ fontFamily: 'PT Sans, sans-serif', color: '#52070c' }} />
                    </ListItem>

                    <ListItem component={Link} to="/promotions" onClick={handleMenuClick}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="promotions">📦</span>
                      </ListItemIcon>
                      <ListItemText primary="Promotions" sx={{ fontFamily: 'PT Sans, sans-serif', color: '#52070c' }} />
                    </ListItem>

                    <ListItem
                      component={Link}
                      to={userEmail ? "/customer-service" : "#"}
                      onClick={() => {
                        if (!userEmail) {
                          setIsLoginDialogOpen(true);
                        }
                        handleMenuClick();
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="contact">☎️</span>
                      </ListItemIcon>
                      <ListItemText primary="Contact Us" sx={{ fontFamily: 'PT Sans, sans-serif', color: '#52070c' }} />
                    </ListItem>
                    <Divider sx={{ my: 1, borderColor: 'rgba(137, 117, 44, 0.3)' }} />
                  </>
                )}

                {userEmail && (
                  <>
                    <ListItem component={Link} to="/profile" onClick={handleMenuClick}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="account details">👤</span>
                      </ListItemIcon>
                      <ListItemText primary="Account Details" sx={{ fontFamily: 'PT Sans, sans-serif', color: '#52070c' }} />
                    </ListItem>
                    <ListItem component={Link} to="/downline" onClick={handleMenuClick}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="downline">🤝🏻</span>
                      </ListItemIcon>
                      <ListItemText primary="My Downline" sx={{ fontFamily: 'PT Sans, sans-serif', color: '#52070c' }} />
                    </ListItem>
                    <ListItem component={Link} to="/deposit" onClick={() => {handleMenuClick();}}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="deposit">💰</span>
                      </ListItemIcon>
                      <ListItemText primary="Deposit" sx={{ fontFamily: 'PT Sans, sans-serif', color: '#52070c' }} />
                    </ListItem>
                    {/* <ListItem component={Link} to="/bank-statement" onClick={() => {handleMenuClick();}}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="deposit statement">🧾</span>
                      </ListItemIcon>
                      <ListItemText primary="Deposit Statement" sx={{ fontFamily: 'PT Sans, sans-serif', color: '#52070c' }} />
                    </ListItem> */}
                    <ListItem component={Link} to="/withdrawal" onClick={() => {handleMenuClick(); }}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="withdrawal">🏦</span>
                      </ListItemIcon>
                      <ListItemText primary="Withdrawal" sx={{ fontFamily: 'PT Sans, sans-serif', color: '#52070c' }} />
                    </ListItem>
                    <ListItem component={Link} to="/statement" onClick={() => {handleMenuClick();}}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="statement">🧾</span>
                      </ListItemIcon>
                      <ListItemText primary="Statement" sx={{ fontFamily: 'PT Sans, sans-serif', color: '#52070c' }} />
                    </ListItem>
                    {/* <ListItem component={Link} to="/withdrawal-list" onClick={() => {handleMenuClick();}}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="withdrawal history">🕔</span>
                      </ListItemIcon>
                      <ListItemText primary="Withdrawal History" sx={{ fontFamily: 'PT Sans, sans-serif', color: '#52070c' }} />
                    </ListItem> */}
                    <ListItem component={Link} to="/records" onClick={() => {handleMenuClick(); }}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="records">📊</span>
                      </ListItemIcon>
                      <ListItemText primary="Records" sx={{ fontFamily: 'PT Sans, sans-serif', color: '#52070c' }} />
                    </ListItem>
                    <ListItem component={Link} to="/sign-in-rewards" onClick={() => {handleMenuClick(); }}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="rewards">🎁</span>
                      </ListItemIcon>
                      <ListItemText primary="Sign-in Rewards" sx={{ fontFamily: 'PT Sans, sans-serif', color: '#52070c' }} />
                    </ListItem>
                    <ListItem
                      button
                      component={Link}
                      to={userEmail ? "/about-us" : "#"}
                      onClick={() => {
                        if (!userEmail) {
                          setIsLoginDialogOpen(true);
                        }
                        handleMenuClick();
                      }}
                    >
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="about us" style={{ paddingLeft: '1px' }}>❓</span>
                      </ListItemIcon>
                      <ListItemText primary="About Us" sx={{ fontFamily: 'PT Sans, sans-serif', color: '#52070c' }} />
                    </ListItem>
                    <Divider sx={{ my: 1, borderColor: 'rgba(137, 117, 44, 0.3)' }} />
                    <ListItem onClick={handleLogout}>
                      <ListItemIcon sx={{ minWidth: '36px' }}>
                        <span role="img" aria-label="logout">🚪</span>
                      </ListItemIcon>
                      <ListItemText primary="Logout" sx={{ fontFamily: 'PT Sans, sans-serif', color: '#800000' }} />
                    </ListItem>
                  </>
                )}
              </List>
            </Drawer>
          </Box>
        </Toolbar>
      </AppBar>

      <LoginDialog
        open={isLoginDialogOpen}
        onClose={() => setIsLoginDialogOpen(false)}
        onLoginSuccess={handleLoginSuccess}
      />

      <RegisterDialog
        open={isRegisterDialogOpen}
        onClose={() => setIsRegisterDialogOpen(false)}
        onRegisterSuccess={handleRegisterSuccess} 
      />
    </>
  );
}

export default CustomAppBar;
