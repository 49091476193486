import React, { useState, useEffect, useRef } from 'react';
import { TextField, IconButton, InputAdornment, Divider } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { postRegister } from '../../services/repositories/RegisterService'; 
import CustomDialog from '../customdialog/CustomDialog';
import { postLoginGoogle } from '../../services/repositories/LoginGoogleService';

function RegisterDialog({ open, onClose, onRegisterSuccess }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false);

   const googleButtonRef = useRef(null);
 
   const handleCredentialResponse = (async (response) => {
     if (response.credential) {
       setLoading(true);
       setError('');
       const decodedCredential = parseJwt(response.credential);
 
       try {
         const response = await postLoginGoogle({
           name: decodedCredential.name,
           email: decodedCredential.email
         });
 
         if (response && typeof response === 'object') {
           if (response.code !== 2000){
             setLoading(false); 
             setError(response.message);
             
           } else if (response.accessToken) {
             onRegisterSuccess({
               email: decodedCredential.email,
               accessToken: response.accessToken,
             });
             onClose();
           } else {
             throw new Error('Access token missing in response');
           }
         } else {
           throw new Error('Invalid response format');
         }
       } catch (err) {
         setError(err.message);
         console.error('Error during Google login:', err.message || err);
       } finally {
         setLoading(false);
       }
     } else {
       setError('Google Sign-In Error');
     }
   });
 
   const initializeGoogleSignIn = (() => {
     if (window.google?.accounts?.id && googleButtonRef.current) {
       window.google.accounts.id.initialize({
         client_id: process.env.REACT_APP_CLIENT_ID?.trim(),
         callback: handleCredentialResponse,
       });
     }
     
   
     try {
       if (window.google.accounts.id && googleButtonRef.current) {
         window.google.accounts.id.renderButton(googleButtonRef.current, {
           theme: "outline",
           size: "large",
           text: "signin_with",
           shape: "rectangular",
         });
       }
     } catch (e) {
       console.error("Error initializing Google Sign-In:", e);
     }
   });
 
   useEffect(() => {
     if (googleButtonRef.current) {
       const script = document.createElement('script');
       script.src = 'https://accounts.google.com/gsi/client';
       script.async = true;
       script.onload = initializeGoogleSignIn;
       document.body.appendChild(script);
 
       return () => {
         document.body.removeChild(script);
       };
     }
   }); 
  
    const parseJwt = (token) => {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
          .join('')
      );
      return JSON.parse(jsonPayload);
    };

  const handleRegister = async () => {
    setLoading(true);
    setError('');

    if (!name || !email || !password || !confirmPassword || !phone || !referralCode) {
      setError('All fields are required.');
      setLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      setLoading(false);
      return;
    }

    if (!/^\d+$/.test(phone)) {
      setError('Phone number must contain only digits.');
      setLoading(false);
      return;
    }

    if (phone.length < 10) {
      setError('Phone number must be valid.');
      setLoading(false);
      return;
    }

    try {
      const response = await postRegister({
        name,
        email,
        password,
        phone,
        referral_code: referralCode,
      });

      if (response && response.code === 2000) {
        setIsSuccessDialogOpen(true);
      } else {
        const errorMessage = 
          response.data?.name?.[0] || 
          response.data?.email?.[0] || 
          response.data?.password?.[0] || 
          response.data?.phone?.[0] || 
          response.data?.referralCode?.[0] || 
          response.message;
          console.log(response.data);
        setError(errorMessage);
      }
    } catch (err) {
      setError(err.message || 'Registration failed. Please try again.');
      console.error('Error during registration:', err.message || err);
    } finally {
      setLoading(false);
    }
  };

  const handleSuccessDialogClose = () => {
    setIsSuccessDialogOpen(false);
    onRegisterSuccess();
    onClose();
  };

  return (
    <>
      <CustomDialog
        open={open}
        onClose={onClose}
        title="Register"
        onSubmit={handleRegister}
        submitLabel="Register"
        submitLoading={loading}
        submitDisabled={loading}
      >

      <div
        id="google-sign-in-button"
        ref={googleButtonRef}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          pointerEvents: loading ? 'none' : 'auto',
          opacity: loading ? 0.5 : 1, 
        }}
      ></div>

      <Divider
        sx={{
          marginY: 2,
          '&::before, &::after': {
            borderColor: '#581a7d',
          },
        }}
      >
        OR
      </Divider>

      {error && <p style={{ color: 'red' }}>{error}</p>}

        <TextField
          label="Username"
          type="text"
          fullWidth
          variant="outlined"
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            sx: {
              fontFamily: 'PT Sans',
              color: '#52070c',
            },
          }}
          InputLabelProps={{
            sx: {
              fontFamily: 'PT Sans',
              color: '#52070c',
              '&.Mui-focused': {
                color: '#52070c',
              },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#52070c',
              },
              '&:hover fieldset': {
                borderColor: '#52070c',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#52070c',
              },
            },
          }}
        />
        <TextField
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          margin="normal"
          value={name}
          onChange={(e) => setName(e.target.value)}
          InputProps={{
            sx: {
              fontFamily: 'PT Sans',
              color: '#52070c',
            },
          }}
          InputLabelProps={{
            sx: {
              fontFamily: 'PT Sans',
              color: '#52070c',
              '&.Mui-focused': {
                color: '#52070c',
              },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#52070c',
              },
              '&:hover fieldset': {
                borderColor: '#52070c',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#52070c',
              },
            },
          }}
        />
        <TextField
          label="Password"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          variant="outlined"
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              fontFamily: 'PT Sans',
              color: '#52070c',
            },
          }}
          InputLabelProps={{
            sx: {
              fontFamily: 'PT Sans',
              color: '#52070c',
              '&.Mui-focused': {
                color: '#52070c',
              },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#52070c',
              },
              '&:hover fieldset': {
                borderColor: '#52070c',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#52070c',
              },
            },
          }}
        />
        <TextField
          label="Confirm Password"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          variant="outlined"
          margin="normal"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            sx: {
              fontFamily: 'PT Sans',
              color: '#52070c',
            },
          }}
          InputLabelProps={{
            sx: {
              fontFamily: 'PT Sans',
              color: '#52070c',
              '&.Mui-focused': {
                color: '#52070c',
              },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#52070c',
              },
              '&:hover fieldset': {
                borderColor: '#52070c',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#52070c',
              },
            },
          }}
        />
        <TextField
          label="Phone"
          type="tel"
          fullWidth
          variant="outlined"
          margin="normal"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          inputProps={{ 
            pattern: '[0-9]*', 
            inputMode: 'numeric' 
          }}  
          onKeyPress={(e) => {
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault();
            }
          }}
          InputProps={{
            sx: {
              fontFamily: 'PT Sans',
              color: '#52070c',
            },
          }}
          InputLabelProps={{
            sx: {
              fontFamily: 'PT Sans',
              color: '#52070c',
              '&.Mui-focused': {
                color: '#52070c',
              },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#52070c',
              },
              '&:hover fieldset': {
                borderColor: '#52070c',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#52070c',
              },
            },
          }}
        />
        <TextField
          label="Referral Code"
          type="text"
          fullWidth
          variant="outlined"
          margin="normal"
          value={referralCode}
          onChange={(e) => setReferralCode(e.target.value)}
          InputProps={{
            sx: {
              fontFamily: 'PT Sans',
              color: '#52070c',
            },
          }}
          InputLabelProps={{
            sx: {
              fontFamily: 'PT Sans',
              color: '#52070c',
              '&.Mui-focused': {
                color: '#52070c',
              },
            },
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#52070c',
              },
              '&:hover fieldset': {
                borderColor: '#52070c',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#52070c',
              },
            },
          }}
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </CustomDialog>

      <CustomDialog
        open={isSuccessDialogOpen}
        onClose={handleSuccessDialogClose}
        title="Successful"
        onSubmit={handleSuccessDialogClose}
        submitLabel="Ok"
      >
        <p>Please login to your account</p>
      </CustomDialog>
    </>
  );
}

export default RegisterDialog;
