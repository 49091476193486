import axios from 'axios';
import { API_ENDPOINTS } from '../../config/ApiConfig';
import LoginModel from '../models/LoginModel';

export const postLoginGoogle = async ({ email, name }) => {
  try {
    const response = await axios.post(API_ENDPOINTS.postLoginGoogle, {
      email,
      name
    });

    const data = response.data;

    return new LoginModel(data);
  } catch (error) {
    if (error.response) {
      const data = error.response.data;
      console.error(`API Error: (${data.status}) ${data.code} - ${data.message}`);
      return new LoginModel(data);
    } else {
      console.error('Error:', error.message || error);
    }
    throw error;
  }
};
