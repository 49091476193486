const API_BASE_URL = process.env.REACT_APP_API_BASE_URL.trim();
const API_PROD_URL = process.env.REACT_APP_API_PROD_URL.trim();
const API_URL = process.env.REACT_APP_ENV.trim() === 'production' ? API_PROD_URL : API_BASE_URL;

const API = 'api/';
const CUSTOMER = 'customer/';
const STORAGE = 'storage/';
// const PRODUCTS = 'products/';
const HOME_POPUP = 'homepopup';
const PRODUCT_LIST = 'product_list';
const PROMO = 'promotions';
const ABOUT = 'aboutus';
const HELP_CENTER = 'helpcenter';
const LOGIN = 'login';
const LOGOUT = 'logout';
const REGISTER = 'register';
const CUSTOMER_SERVICE_LINK = 'customer_service_link';
const PROFILE = 'profile';
const PASSWORD_CHANGE = 'password_change';
const SUBMIT_BOOKING = 'submit_booking';
const BOOKING_DETAIL = 'booking_detail';
const SUBMIT_ORDER = 'submit_order';
const SUBMIT_LUCKY_DRAW = 'submit_lucky_draw';
const ORDER_LIST = 'order_list';
const BANK_STATEMENT = 'bank_statement';
const ADD_BANK = 'add_bank';
const WITHDRAWAL_LIST = 'withdrawal_list';
const WITHDRAWAL_CREATE = 'withdrawal_create';
const LOGIN_GOOGLE = 'login_google';

export const API_ENDPOINTS = {
  displayImage: `${API_URL}${STORAGE}`,
  // displayProduct: `${API_URL}${STORAGE}${PRODUCTS}`,
  getHomePopUp: `${API_URL}${API}${HOME_POPUP}`,
  getProductList: `${API_URL}${API}${PRODUCT_LIST}`,
  getPromos: `${API_URL}${API}${PROMO}`,
  getAbout: `${API_URL}${API}${CUSTOMER}${ABOUT}`,
  getHelpCenter: `${API_URL}${API}${CUSTOMER}${HELP_CENTER}`,
  postLogin: `${API_URL}${API}${LOGIN}`,
  postLogout: `${API_URL}${API}${LOGOUT}`,
  postRegister: `${API_URL}${API}${REGISTER}`,
  getCustomerServiceLink: `${API_URL}${API}${CUSTOMER_SERVICE_LINK}`,
  getProfile: `${API_URL}${API}${CUSTOMER}${PROFILE}`,
  postPasswordChange: `${API_URL}${API}${CUSTOMER}${PASSWORD_CHANGE}`,
  postSubmitBooking: `${API_URL}${API}${CUSTOMER}${SUBMIT_BOOKING}`,
  getBookingDetail: `${API_URL}${API}${CUSTOMER}${BOOKING_DETAIL}`,
  postSubmitOrder: `${API_URL}${API}${CUSTOMER}${SUBMIT_ORDER}`,
  postSubmitLuckyDraw: `${API_URL}${API}${CUSTOMER}${SUBMIT_LUCKY_DRAW}`,
  getOrderList: `${API_URL}${API}${CUSTOMER}${ORDER_LIST}`,
  getBankStatement: `${API_URL}${API}${CUSTOMER}${BANK_STATEMENT}`,
  postAddBank: `${API_URL}${API}${CUSTOMER}${ADD_BANK}`,
  getWithdrawalList: `${API_URL}${API}${CUSTOMER}${WITHDRAWAL_LIST}`,
  postWithdrawalCreate: `${API_URL}${API}${CUSTOMER}${WITHDRAWAL_CREATE}`,
  postLoginGoogle: `${API_URL}${API}${LOGIN_GOOGLE}`,
};
